import ptBtnYq from '../../components/ptBtn.vue' //优惠券
import ticketCode from '../../components/ticketCode.vue'
export default {
    name: 'payState',
    components: {
        ptBtnYq,
        ticketCode
    },
    data() {
        return {
            price: 0,
            state: null,
            tickeShow: false, //显示二维码
            title: '',
            order_id: null,
            is_pt: false,
            orderInfo: null,
            timer: ['00', '00', '00', '00']

        }
    },
    created() {
        this.price = this.$route.query.price;
        this.state = this.$route.query.type;
        this.is_pt = this.$route.query.is_pt ? true : false; //是否拼团
        this.order_id = this.$route.query.order_id;
        if (this.is_pt) {
            let _this = this;
            this.$UtilsApi.orderInfo(this.$route.query.order_id, {}).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data.group_status == 2) {
                        localStorage.setItem('login_data', JSON.stringify({
                            name: 'ptOrderInfo',
                            id: _this.$route.query.order_id,
                        }));
                        _this.toHome();
                        return
                    }
                    this.orderInfo = res.data.data;
                    console.log(res.data.data)

                    function countdown(pay_time, work_time) {
                        // 计算结束时间戳（单位：毫秒）
                        var end_time = new Date(pay_time + work_time * 60 * 60 * 1000).getTime();

                        // 获取当前时间戳
                        var current_time = new Date().getTime();

                        // 计算剩余时间（单位：毫秒）
                        var remaining_time = end_time - current_time;

                        // 如果剩余时间小于等于0，表示已经过了结束时间
                        if (remaining_time <= 0) {
                            return [0, 0, 0, 0]; // 返回全为0的数组表示倒计时结束
                        }

                        // 计算时、分、秒、毫秒
                        var hours = Math.floor(remaining_time / (1000 * 60 * 60));
                        var minutes = Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((remaining_time % (1000 * 60)) / 1000);
                        var milliseconds = remaining_time % 1000;

                        // 返回时、分、秒、毫秒的数组
                        _this.timer = [hours < 10 ? '0' + hours : hours, minutes < 10 ? '0' + minutes : minutes, seconds < 10 ? '0' + seconds : seconds, Math.floor(milliseconds / 100) < 10 ? '0' + Math.floor(milliseconds / 100) : Math.floor(milliseconds / 100)];
                        // 每隔10毫秒更新倒计时显示
                        setTimeout(function() {
                            countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                        }, 100);
                    };
                    countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                    this.orderInfo.is_today = this.getTimerState(res.data.data.act_end_time) > 0 || (res.data.data.status == 1 && !this.isToday(res.data.data.order_time));
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        }
        this.title = this.$route.query.type == 'ok' ? '支付成功' : '支付失败，请重新支付';
        document.title = this.$route.query.type == 'ok' ? '支付成功' : '支付失败';
    },
    methods: {
        // 支付
        payment(data) {
            let _this = this;
            if (typeof WeixinJSBridge == 'undefined') return;
            this.$UtilsApi.payment(data.id, {}).then(res => {
                let paymentData = JSON.parse(res.data.data.payment);
                WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": _this.$appId, //公众号ID，由商户传入    
                    "timeStamp": paymentData.timestamp, //时间戳，自1970年以来的秒数    
                    "nonceStr": paymentData.nonceStr, //随机串    
                    "package": paymentData.package,
                    "signType": paymentData.signType, //微信签名方式：    
                    "paySign": paymentData.paySign //微信签名
                }, function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        var data = {
                            type: 'ok',
                            order_id: _this.$route.query.order_id,
                            price: _this.$route.query.price
                        };
                        _this.$route.query.is_pt ? data.is_pt = 1 : '';
                        _this.title = '支付成功';
                        _this.state = 'ok';
                        _this.price = _this.$route.query.price;
                        _this.$router.replace({
                            name: 'payState',
                            query: data
                        })

                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        var data = {
                            type: 'no',
                            order_id: _this.$route.query.order_id,
                            price: _this.$route.query.price
                        };
                        _this.$route.query.is_pt ? data.is_pt = 1 : '';
                        _this.$route.query.is_pt ? data.to = 1 : '';
                        _this.title = '支付失败，请重新支付';
                        _this.state = 'no';
                        _this.price = _this.$route.query.price;
                        _this.$router.replace({
                            name: 'payState',
                            query: data
                        })
                    } else {
                        var data = {
                            type: 'qx',
                            order_id: _this.$route.query.order_id,
                            price: _this.$route.query.price
                        };
                        _this.$route.query.is_pt ? data.is_pt = 1 : '';
                        _this.$route.query.is_pt ? data.to = 1 : '';
                        _this.title = '支付失败，请重新支付';
                        _this.state = 'qx';
                        _this.price = _this.$route.query.price;
                        _this.$router.replace({
                            name: 'payState',
                            query: data
                        })
                    }
                });
            })

        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        toHome() {
            this.$route.query.mall_id = localStorage.getItem('mall_id');
            this.$router.push({
                name: 'home',
                query: this.$route.query
            });
        },
        toOrderInfo() {
            this.$router.replace({
                name: this.$route.query.to ? 'ptOrderInfo' : 'orderInfo',
                query: {
                    id: this.$route.query.order_id,
                }
            })
        }
    },
}